<template>
  <div class="px-2 md:px-4 pt-16">
    <div class="flex flex-wrap min-h-screen">
      <div class="w-full md:w-1/2">
        <div class="w-3/5 self-start">
          <img class="w-full" :src="siteOption.aboutDetailImage.url" alt="">
          <div class="pt-1">{{siteOption.aboutDetailImage.imagecaption}}</div>
        </div>
      </div>   
      <div class="mt-20 mb-20 md:mt-0 text-lg whitespace-pre-line w-full ipad:w-1/2 p-rules mb-40 pr-2">
        <div class="pr-2" v-html="siteOption.about.html"></div>
        <div v-for="personFeature in siteOption.featurePerson" :key="personFeature.name" class="text-lg">
          <img class="pr-2 mt-4 w-full md:w-1/2 ipad:w-full" :src="personFeature.image.url" alt="">
          <div class="mt-4">{{personFeature.name}} <br>
            {{personFeature.position}} <br>
          </div>
          <div v-html="personFeature.biography.html" class="mt-4 p-rules"></div>
        </div>
      </div>      
    </div>



    <Footer></Footer>

  </div>
</template>

<script>
import gql from "graphql-tag";
import Footer from '@/components/Footer.vue'



export default {
  name: 'Information',
  components: {
    Footer
  },
  mounted () {
    window.scrollTo(0, 0)
  },    
  apollo: {
    siteOption: {
      query: gql`
        {
          siteOption(where: {id: "cklhk9xsw22r10940gafwisdg"}) {
            addressNumber
            address2
            instagram
            phoneNumber
            privacyPolicy {
              html
            }
            aboutDetailImage {
              url
              imagecaption
            }       
            collaborators
            about {
              html
            }
            team {
              name
              position
              email
            }
            featurePerson {
              name
              position
              email
              biography {
                html
              }
              image {
                url
              }
            }            
          }
        }
      `
    }
  }
}
</script>

<style>

</style>

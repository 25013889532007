<template>
  <div class="px-2 md:px-4 pt-18">
    <div class="flex flex-wrap text-lg min-h-screen">      
      <div class="w-full md:w-1/2">
      <div class="p-rules-mb p-rules-b mb-4 whitespace-pre-line text-lg" v-html="siteOption.thanksMessage"></div>
      </div>        
    </div>

    <Footer></Footer>

  </div>
</template>

<script>
import gql from "graphql-tag";
import Footer from '@/components/Footer.vue'



export default {
  name: 'Thanks',
  components: {
    Footer
  },
  mounted () {
    window.scrollTo(0, 0)
  },    
  apollo: {
    siteOption: {
      query: gql`
        {
          siteOption(where: {id: "cklhk9xsw22r10940gafwisdg"}) {
            addressNumber
            address2
            email
            instagram
            phoneNumber
            contactDetailImage {
              url
            }
            thanksMessage         
            contactText {
              html
            }
            privacyPolicy {
              html
            }
            collaborators
            about {
              html
            }
            team {
              name
              position
              email
            }
            featurePerson {
              name
              position
              email
              biography {
                html
              }
              image {
                url
              }
            }            
          }
        }
      `
    }
  }
}
</script>

<style>

</style>

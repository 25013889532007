<template>
  <div id="app" class="min-h-screen">
      <Nav></Nav>
    <router-view/>
  </div>
</template>

<script>
import Nav from '@/components/Nav.vue'

export default {
    components: {
      Nav
  }
}
</script>

<style lang="scss">

body{
  font-family: 'BasisGrotesque-Regular', arial;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;  
  font-size: 1em;
  min-height: -webkit-fill-available;
  min-height: 100vh;
}

#app{
  font-family: 'BasisGrotesque-Regular', arial;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;  
  background-color: #F2F2F0;
  font-size:0.9em;
}

.nav{
 a {
   color:black;
   text-decoration: none;
 }
}

a {
  color:black;
  text-decoration: none;
}

.router-link-exact-active {

}

.ff-basis {
  font-family: 'BasisGrotesque-Regular', arial;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family:BasisGrotesque-Regular;
  src:  url('./fonts/BasisGrotesque-Regular.woff') format('woff');
}

</style>

<template>
  <div class="px-2 md:px-4 pt-16">

    <div class="flex flex-wrap -mx-0 md:-mx-2 mb-0">
      <ProjectBlock v-for="project in siteOption.projectsOrder"
      :thumbnail="project.thumbnail"
      :name="project.name"
      :streetNumber="project.streetNumber"
      :byline="project.byline"
      :cardDescription="project.cardDescription"
      :slug="project.slug"
      :key="project.id"
      >
      </ProjectBlock>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import gql from "graphql-tag";
import Footer from '@/components/Footer.vue'
import ProjectBlock from '@/components/ProjectBlock.vue'


export default {
  name: 'Projects',
  components: {
    Footer,
    ProjectBlock
  },
  mounted () {
    window.scrollTo(0, 0)
  },    
  apollo: {
    siteOption: {
      query: gql`
query MyQuery {
  siteOption(where: {id: "cklhk9xsw22r10940gafwisdg"}) {
    projectsOrder {
          thumbnail {
      url
    }
    streetNumber
    name
    byline
    cardDescription
    slug
    }
  }
}
      `
    }
  }
}
</script>

<style>

</style>

<template>
  <div class="px-2 md:px-4 pt-16">
    <div class="flex flex-wrap md:min-h-screen">
      <div class="w-full md:w-1/2">
        <div class="w-3/5 self-start">
          <img class="w-full" :src="siteOption.contactDetailImage.url" alt="">
          <div class="pt-1">{{siteOption.contactDetailImage.imagecaption}}</div>
        </div>
      </div>        
      <div class="mt-20 mb-40 md:mt-0 w-full md:w-1/2 text-lg">
      <div class="p-rules mb-4 whitespace-pre-line text-lg" v-html="siteOption.contactText.html"></div>
      <div class="mb-4">Contact</div>
      <div class="w-1/2 md:w-1/4">{{siteOption.phoneNumber}}</div>
      <div class="w-1/2 md:w-1/4"><a class="underline" :href="'mailto:' + siteOption.email">{{siteOption.email}}</a></div> <br>        
      <div class="w-full md:w-1/4">{{siteOption.addressNumber}}</div>
      <div class="w-full md:w-1/4 whitespace-pre-line">{{siteOption.address2}} </div>
      </div>        
    </div>

    <Footer></Footer>

  </div>
</template>

<script>
import gql from "graphql-tag";
import Footer from '@/components/Footer.vue'



export default {
  name: 'Contact',
  components: {
    Footer
  },
  mounted () {
    window.scrollTo(0, 0)
  },    
  apollo: {
    siteOption: {
      query: gql`
        {
          siteOption(where: {id: "cklhk9xsw22r10940gafwisdg"}) {
            addressNumber
            address2
            email
            instagram
            phoneNumber
            contactDetailImage {
              url
              imagecaption
            }            
            contactText {
              html
            }
            privacyPolicy {
              html
            }
            collaborators
            about {
              html
            }
            team {
              name
              position
              email
            }
            featurePerson {
              name
              position
              email
              biography {
                html
              }
              image {
                url
              }
            }            
          }
        }
      `
    }
  }
}
</script>

<style>

</style>

<template>
  <div class="nav fixed w-full z-40 bg-white shadow-md" :class="active ? 'slide' : ''">
    <div class="w-full flex flex-wrap px-2 py-4 md:px-4 md:py-4 logo-bar">
      <div class="logo w-24 md:w-1/3 lg:w-1/2">
        <router-link to="/">
        <img class="w-24" :src="siteOption.logo.url" alt="">
        </router-link>
      </div>
      <div class="menu hidden md:flex md:w-2/3 lg:w-1/2 flex-wrap justify-end uppercase items-center">
        <router-link class="mr-10" to="/projects">Projects</router-link>
        <router-link class="mr-10" to="/information">Information</router-link>
        <router-link to="/contact">Contact</router-link>
      </div>      
    </div>
      <div class="menu-mobile block md:hidden text-lg px-2 py-4" :class="active ? 'slide' : ''">
        <span class="block"><router-link class="" to="/projects">Projects</router-link></span>
        <span class="block"><router-link class="" to="/information">Information</router-link></span>
        <span class="block"><router-link class="" to="/contact">Contact</router-link></span>
      </div>
      <div class="menu-icon fixed block md:hidden" :class="active ? 'toggled' : ''">
      <div v-on:click="active=!active">
        <span class="a"></span>
        <span class="b"></span>
        <span class="c"></span>  
      </div>  
      </div>    
  </div>
</template>

<script>
import gql from "graphql-tag";



export default {
  name: 'Nav',
  data(){
    return {
      active: false,
      logo: false
    }
  },
  components: {
    
  },
  watch: {
    '$route' () {
      this.active = false
    }
  },  
  apollo: {
    siteOption: {
      query: gql`
        {
          siteOption(where: {id: "cklhk9xsw22r10940gafwisdg"}) {
            logo {
              url
            }
          }
        }
      `
    }
  }
}
</script>

<style>

.nav{
  top:0;
  left:0px;
  height: 49px;
  transition:0.5s;
  overflow: hidden;
}

.nav.slide {
  height: 140px;
  transition:0.5s;
}

.menu-icon {
  top:10px;
  right:0.2em;
}

.menu-mobile {
  
  
}

.menu-mobile a {
  
}

.menu-mobile.slide {
  
  
}

.menu-mobile.slide a {
  color:black !important;
}

.router-link-exact-active {
  color:lightslategray !important;
}


.menu-icon {
  display: block;
  width: 40px;
  height: 40px;
  box-sizing: border-box;
  padding: 5px 5px;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.menu-icon.toggled .a {
  transform: translateY(6px) rotate(45deg);
}
.menu-icon.toggled .b {
  opacity: 0;
  margin: 4px 0;
}
.menu-icon.toggled .c {
  transform: translateY(-6px) rotate(-45deg);
}
.menu-icon span {
  display: block;
  background: rgba(0, 0, 0, 0.75);
  width: 28px;
  height: 2px;
  transform-origin: center;
  transition: 0s ease;
}
.menu-icon .b {
  margin: 6px 0;
}


</style>

<template>
  <div class="w-full md:w-1/2 px-0 md:px-2 mb-8 md:mb-4 h-0 md:h-1/3 ">
     <router-link :to="'/projects/'+ slug"> 
      <div @mouseover="cardHover = true" @mouseleave="cardHover = false" class="static md:relative">
        <img class="w-full h-33 md:h-1/3 object-cover static md:absolute inset-0 z-20" :src="thumbnail.url" alt="">
        <div :class="{'z-30': cardHover}"
          class="w-auto md:w-full hidden md:flex h-33 md:h-1/3 absolute inset-0 p-2 bg-white shadow-md flex-wrap flex-col justify-between">
          <div class="text-3xl ff-s">
            <div>{{streetNumber}} {{name}}</div>
            <div>{{byline}}</div>
          </div>
          <div class="">
            <div class="whitespace-pre-line">{{cardDescription}}</div>
          </div>
        </div>
          <div class="block md:hidden text-lg mt-2">
            <div>{{streetNumber}} {{name}}</div>
            <div>{{byline}}</div>
          </div>
          <div class="block md:hidden">
            <div class="whitespace-pre-line">{{cardDescription}}</div>
          </div>        
      </div>
    </router-link>
  </div>


  
</template>

<script>



export default {
  name: 'ProjectBlock',
  data(){
    return {
      cardHover: false
    }
  },
  components: {
    
  },
  props: {
    thumbnail: Object,
    name: String,
    streetNumber: String,
    byline: String,
    cardDescription: String,
    slug: String

  }      
}
</script>

<style>
.object-cover {
  object-fit: cover;
}
</style>


import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Projects from '../views/Projects.vue'
import Project from '../views/Project.vue'
import Information from '../views/Information.vue'
import Contact from '../views/Contact.vue'
import Privacy from '../views/Privacy.vue'
import Thanks from '../views/Thanks.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/projects',
    name: 'Projects',
    component: Projects
  }, 
  {
    path: '/projects/:slug',
    name: 'Project',
    component: Project
  },   
  {
    path: '/information',
    name: 'Information',
    component: Information
  }, 
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/privacy-policy',
    name: 'Privacy',
    component: Privacy
  },
  {
    path:'/thanks',
    name: 'Thanks',
    component: Thanks
  }  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

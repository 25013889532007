<template>
  <div class="px-2 md:px-4 pt-16">

      <div v-if="project.heroImage" class="w-full pb-4 mh-hero">
        <img :src="project.heroImage.url" class="w-full" alt="">
      </div>

      <div class="flex flex-wrap justify-between">
        <div class="text-3xl ff-s">
        <span v-if="project.streetNumber">{{project.streetNumber}}</span> 
        <span v-if="project.name"></span>
        {{project.name}} <br>
        <span v-if="project.byline">{{project.byline}}</span>
        </div>
        <div v-if="project.enquireLink" class="text-3xl ff-s mt-20 md:mt-0">
          <span v-on:click="active=!active" class="cursor-pointer">Enquire</span>
        </div>
      </div>

      <div class="lightbox w-full h-screen fixed flex items-center justify-center top-0 left-0" v-show="active">
        <div class="background-close cursor-pointer" v-on:click.self="active=!active"></div>
        <span v-on:click="active=!active" id="closeEnquire">
          <span class="cross"></span>
        </span>
        <form-updated :title="project.name" :streetNumber="project.streetNumber" :formlink="project.enquireLink"></form-updated>
      </div>

      <div v-html="project.longDescription.html" 
           class="whitespace-preline w-full md:w-3/4 mt-0 md:mt-20 mb-4 text-lg">
      </div>

      <!-- <div class="flex flex-wrap -mx-0 md:-mx-2 mb-4">
        <div class="px-0 py-2 mb-4 md:py-0 md:px-2 w-full md:w-1/2 ipad:w-1/3  flex flex-wrap content-end" v-for="image in project.images">
              <img class="w-auto sm:w-full h-auto" :src="image.url" alt="">
          </div>
      </div> -->

      <Lightbox :items="project.images">

      </Lightbox>

      <div class="flex flex-wrap -mx-2 mb-40">
        <div class="text-lg w-full md:w-1/2 px-2">
          <div class="mb-4">{{project.streetNumber}} {{project.name}}</div>
          <div v-html="project.details.html" class="mb-4"></div>
          <a v-if="project.googleMapsLink" :href="project.googleMapsLink" class="underline" target="_blank">Google Maps</a>
        </div>
        <div class="w-full md:w-1/2 text-lg px-2 mt-4 md:mt-0">
          <div v-if="project.websiteLink">Website</div>
          <div v-if="project.websiteLink" class="mb-4"><a target="_blank" :href="project.websiteLink" class="underline">{{project.websiteTitle}}</a></div>
          <div v-if="project.enquireLink" class="">
          <span v-on:click="active=!active" class="cursor-pointer underline">Enquire</span> about this project</div>
          <a v-if="project.floorplans" :href="project.floorplans.url" class="underline">Download floorplans</a>
        </div>
      </div>
      
      <Footer></Footer>

  </div>
</template>

<script>
import gql from "graphql-tag";
import Lightbox from '@/components/Lightbox.vue'
import Footer from '@/components/Footer.vue'
import FormUpdated from '@/components/FormUpdated'
// import Lazy from '@/components/Lazy'

export default {
    name: 'Project',
    data() {
        return {
              routeParam: this.$route.params.slug,
              active: false
        }
    },
    components: {
      Lightbox,
      FormUpdated,
      Footer,
      // Lazy
    },
  watch: {
    $route: 'fetchDataAgain',
  },     
  methods: { 
    fetchDataAgain() { 
      this.routeParam = this.$route.params.slug;
      console.log(this.routeParam);
      this.$apollo.queries.project.skip = false
      this.$apollo.queries.project.refresh();
      }
    },
    mounted () {
      window.scrollTo(0, 0)
    },     
    apollo: {
      project: {
         query: gql`
          query getProject($slug: String) {
            project(where: {slug: $slug}) {
                slug
                heroImageTrans: heroImage {
                  url(transformation: {image: {resize: {width: 10}}})
                }                
                heroImage {
                  url
                }
                streetNumber
                name
                byline
                longDescription {
                  html
                }
                enquireLink
                images {
                  url
                  id
                  imagecaption
                }
                details {
                  html
                }
                googleMapsLink
                emailLink
                websiteLink
                websiteTitle
                floorplans {
                  url
                }                         
            }
          }
      `,
        variables() {
          return {
            slug: this.routeParam
          };
        }       
      }      
    }
}
</script>

<style lang="scss">
.mh-hero img {
  max-height: 150vh;
  width:auto;
}

.lightbox{
  top:0;
  left:0;
  background: rgba(255, 255, 255, 0.9);
  z-index: 100;
}

.background-close {
  position: fixed;
  top:0px;
  left:0px;
  z-index: 97;
  width:100%;
  height:100vh;
}

    #closeEnquire {
      display: block;
      width:60px;
      height:60px;
      position: fixed;
      top:30px;
      right:30px;
      z-index: 999;
      cursor: pointer;
      .cross {
        position: relative;
        &:before,
        &:after {
          position: absolute;
          left: 30px;
          content: " ";
          height: 60px;
          width: 1px;
          background-color: #000;
        }

        &:before {
          transform: rotate(45deg);
        }

        &:after {
          transform: rotate(-45deg);
        }
      }
    }
</style>

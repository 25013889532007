<template>
<div>
		<div class="imageGallery flex flex-wrap -mx-0 md:-mx-2 mb-0 md:mb-4 pointer-events-none sm:pointer-events-auto">
			<div v-for="(item, index) in items" class="px-0 py-1 md:py-2 mb-0 md:py-0 md:px-2 w-full md:w-1/2 ipad:w-1/3 flex flex-wrap content-end">
				<a class="relative" v-bind:href="item.url" v-on:click="lightboxOpen(item.url, item.imagecaption, index, $event)" v-bind:class="{ active: active == index }">
					<img v-bind:src="item.url" >
          <span class="block md:hidden py-1">{{item.imagecaption}}</span>
				</a>
			</div>
		</div>

		<div id="overlay" v-on:click.self="closeOverlay">
			<div class="holder" v-on:click.self="closeOverlay">
        <div>
          	<img id="overlay_image" v-on:click="cycleNext">
        </div>
			</div>
				<div class="text-lg">
					<a id="close" nohref v-on:click="closeOverlay"><span class="cross"></span></a>
          <span id="overlay_caption" class="p-4"></span>
          <span class="counter text-lg mt-2 block">
              <span class="w-4 mx-1 inline-block text-right">{{imageIndex + 1}}</span>
              <span class="w-2 mx-1 inline-block text-center">/</span>
              <span class="w-4 mx-1 inline-block text-center">{{items.length}}</span>
            </span>
				</div>      
		</div>
	</div> 
</template>

<script>




export default {
  name: 'Lightbox',
  data(){
    return {
			showNav: 1,
			loop: 1,
			imageIndex: 0,
			active: 0
		}
  },
  components: {
    
  },
  props: {
    items: Array
  },
	methods: {
		lightboxOpen: function(href, caption, index, event){
			event.preventDefault();		
			document.getElementById("overlay_image").src = href;
      document.getElementById("overlay_caption").innerHTML = caption;
			document.getElementById("overlay").style.display = 'block';
			this.imageIndex = index;			
			this.active = index;
		},
		closeOverlay: function(){
			document.getElementById("overlay").style.display = 'none';
		},
		cycleNext: function(){
			if (this.items.length != this.imageIndex + 1){
				var nextActiveImage = document.querySelector('.active').parentElement.nextSibling.querySelector("img");
				nextActiveImage.click();
			} else if (this.loop == 1){
				var firstActiveImage = document.querySelector('.imageGallery').querySelector("div").querySelector("img");
				firstActiveImage.click();
			} 
		},
		cyclePrev: function(){
			if (this.imageIndex != 0){
				var prevActiveImage = document.querySelector('.active').parentElement.previousSibling.querySelector("img");
				prevActiveImage.click();
				
			} else if (this.loop == 1){
				var lastActiveImage = document.querySelector('.imageGallery').querySelectorAll("div")
				var lastChild = lastActiveImage[lastActiveImage.length-1].querySelector("img");
				lastChild.click();
			} 
		},
	}        
}
</script>


<style lang="scss">
#overlay_caption {
  position: fixed;
  bottom:0px;
  left:0px;
  z-index: 2;
}
#overlay {
  display: none;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.9);
  z-index: 100;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;

  .holder {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 101;
    cursor: pointer;

    img {
      width: auto;
      max-height: 85vh;
      cursor: e-resize;
      box-sizing: border-box;
      display: block;
      &::selection {
        background-color:transparent;
      }
    }
  }

  .counter {
    width:100%;
    position: fixed;
    bottom:2.5vh;
    left:0px;
  }
    #close {
      position: fixed;
      top:30px;
      right:90px;
      z-index: 102;
      .cross {
        position: relative;
        &:before,
        &:after {
          position: absolute;
          left: 30px;
          content: " ";
          height: 60px;
          width: 1px;
          background-color: #000;
        }

        &:before {
          transform: rotate(45deg);
        }

        &:after {
          transform: rotate(-45deg);
        }
      }
    }
  
}
</style>


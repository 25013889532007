<template>
  <div class="home">
    <div class="w-screen h-screen bg-grey object-cover hidden md:block">
      <Slider :image-array="homepage.images" />
      <FooterHome></FooterHome>
    </div>
    <div id="slider-m" class="w-screen slider-m-wrapper bg-grey object-cover md:hidden">
      <SliderM :image-array="homepage.mobileImages" />
      <FooterHome></FooterHome>
    </div>
  </div>
</template> 

<script>
import gql from "graphql-tag";
import Slider from '@/components/Slider.vue'
import SliderM from '@/components/SliderM.vue'
import FooterHome from '@/components/FooterHome.vue'


export default {
  name: 'Home',
  components: {
    Slider,
    SliderM,
    FooterHome
  },
  updated: function (){
                var heights = window.innerHeight;
                document.getElementById("slider-m").style.height = heights + "px";
  },  
  apollo: {
    homepage: {
      query: gql`
        {
         homepage(where: {id: "cklhfy1x48w690b80e17j12wq"}) {
           id
           images {
             url
           }
            mobileImages {
              url
            }           
        }
      }
      `
    }
  }
}
</script>

<style>

.slider img {
    max-width: auto;
    width: auto;
    height: auto;
}
</style>

<template>
  <div class="footer-home flex flex-wrap pb-4 flex-row-reverse md:flex-row w-full text-white fixed">
    <div class="w-full md:w-1/2 flex">
      <div class="w-32 md:w-32">{{siteOption.phoneNumber}}</div>
      <div class="w-full md:w-full">OUTLINE PROJECTS <br>
      <a class="hidden md:block underline" :href="'mailto:' + siteOption.email">{{siteOption.email}}</a>
      <a class="block md:hidden underline" :href="'mailto:' + siteOption.email">Email</a>  
      </div>
    </div>
    <div class="w-full md:w-1/2 flex mt-0 md:mt-4 md:mt-0">
      <div class="w-32 md:w-32 block md:hidden"></div>
      <div class="w-full md:w-full whitespace-pre-line"><a class="underline ":href="'https://instagram.com/' + siteOption.instagram" target="_blank">Instagram</a></div>
    </div>     
    <div class="w-full md:w-1/2 flex mt-4 md:mt-4">
      <div class="w-32 md:w-32">{{siteOption.addressNumber}}</div>
      <div class="w-full md:w-full whitespace-pre-line">{{siteOption.address2}} </div>
    </div>    
    <div class="w-full ml-24 md:ml-0 md:w-1/2 flex flex-wrap mt-4 md:mt-4">
      <div class="w-full md:w-full"><router-link class="underline text-white no-active" to="/privacy-policy">Privacy policy</router-link></div>
    </div>        
  </div>
</template>

<script>
import gql from "graphql-tag";



export default {
  name: 'Footer',
  data(){
    return {
      
    }
  },
  components: {
    
  },
  apollo: {
    siteOption: {
      query: gql`
        {
          siteOption(where: {id: "cklhk9xsw22r10940gafwisdg"}) {
            addressNumber
            email
            address2
            instagram
            phoneNumber
            privacyPolicy {
              html
            }
            collaborators
            about {
              html
            }
          }
        }
      `
    }
  }
}
</script>

<style >
.footer-home {
  bottom:0px;
  left:0.5em;
}

.footer-home a {
  color:white;
}

</style>

<template>
  <section class="form shadow-md w-full md:w-2/5 m-auto bg-white p-4">
    <div class="w-full mb-4 text-lg">To enquire about {{streetNumber}} {{title}}</div>
    <form accept-charset="UTF-8" :action="formlink" method="POST" v-if="!formSubmitted">
      <input class="mb-4 text-lg" type="text" name="name" placeholder="Name*" required/>
      <input class="mb-4 text-lg" type="text" name="phone-number" placeholder="Mobile*" required/>
      <input class="mb-4 text-lg" type="email" name="email" placeholder="Email address*" required/>
      <div class="left">
        <input class="mb-2 text-lg" type="submit" value="Submit" />
      </div>
    </form>
    <h5 class="thanks" v-if="formSubmitted"></h5>
  </section>
</template>

<script>
export default {
  name: 'FormUpdated',
  props: {
    title: String,
    streetNumber: String,
    formlink: String,
    inline: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      investmentSelected: false,
      requiredBedrooms: false,
      formSubmitted: false
    }
  }
}
</script>

<style lang="scss">
.form {
  display:flex !important;
  flex-wrap:wrap;
  align-items: center;
  justify-content: center;
  margin:2rem 0 5rem 0;
  z-index: 192;
  &--inline {
    form {
      border:1px solid;
      padding:1rem 2rem 2rem 2rem;
    }
  }
  form {
    width: 100%;
    display:flex;
    flex-direction: column;
    align-items: center;
    input {
      width:100%;
      border:1px solid rgb(182, 182, 182);
      &[type="text"], &[type="email"] {
        padding:12px 2px;
        color:#000;
        border-radius:0px;
        text-indent: 2px;
        background-color: #fff;
        &::placeholder {
          color:#000;
        }
      }
      &[type="submit"] {
        appearance: none;
        justify-self: center;
        background-color: #fff;
        color:#000;
        padding:12px 2px;
        display:inline;
        max-width:80px;
        border:1px solid rgb(182, 182, 182);
        transition: all 320ms ease;
        cursor:pointer;
        &:hover {
          background-color:#fff;
          color:#000;
        }
      }
      &:focus {
        background-color: #fff;
        color:#000;
      }
      textarea {
        background-color: #fff;
      }
    }
    select {
      font-style: italic;
      width:100%;
      appearance: none;
      background:none;
      outline:none;
      border-radius:0px;
      border-bottom:1px solid;
      height:38px;
      padding:6px 0;
      margin-bottom: 10px;
      opacity:1;
      color:#000;
      border-bottom:1px solid #fff;
      &.selected {
        opacity: 1;
      }
    }
    .left {
      width: 100%;
      text-align:left;
    }
  }
  
}
</style>
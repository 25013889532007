<template>
    <div id="slider-m-inner" >
        <VueSlickCarousel ref="carousel" :accessibility="false" :autoplay="true">
            <div class="test" v-for="(image, indx) in imageArray" :key="indx" :index="indx">
                <div :class="{'cursor-right': imageArray.length > 1}" @click="showNext" class="slider w-screen bg-grey">
                    <img class="" :src="image.url">
                </div>
            </div>
            <template #prevArrow="arrowOption">
                <div class="custom-arrow text-black mono hidden">
                </div>
            </template>
        </VueSlickCarousel>
    </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'


export default {
  name: 'ImageSlider',
  components: {
    VueSlickCarousel
  },
  props: ["image-array"],
  data () {
    return {
      carouselData: 0
    }
  },
  methods: {
      showNext() {
        this.$refs.carousel.next()
      }
  }       
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.slick-slider {height:100%;}
.slick-track {display: flex !important;outline: none !important; height:100%;}
.slick-slide{height: inherit !important;outline: none !important;}
.slick-slide > div { height: 100%;outline: none !important; }
.slick-slide, .slick-slide *{ outline: none !important; }
.slick-list {line-height: 0; height: 100%}
.slick-next {display: none !important;}
.custom-arrow {
  position: absolute;
  top:-25px;
  left:5px;
}
</style>

<style>
#slider-m-inner {
  position:fixed;
  height:100%;
  width:100%;
  top:0px;
  left:0px;
  overflow-y:hidden;
  
}
#slider-m-inner * {
  height:100%;
}
.slider {
    object-fit: cover;
    display: flex;
    align-content: stretch;
    align-items: stretch;
    height:100%;
}
.slider img {
    max-width: inherit;
    max-height: inherit;
    flex: 1;
    object-fit: cover;
}

.cursor-right {
  cursor:e-resize;
}
</style>
